import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { container, infoTitle } from './CompanyInfo.module.scss'

type Props = {
	author: {
		firstName: string
		lastName: string
		name?: string
	}
	category: string
}

const infoData = [
	{ title: '会社名', text: 'Lisa Technologies 株式会社' },
	{ title: '代表者', text: '西村 龍紀' },
	{ title: '設立', text: '2017年8月29日' },
	{ title: '資本金', text: '1億2,350万円（資本準備金を含む）' },
	{ title: '所在地', text: '〒103-0004 東京都中央区東日本橋2丁目24−9 LIT 4F' },
	{ title: '事業内容', text: 'フードデリバリー最適化クラウド「フードデリバリーマネージャー」とフードデリバリーブランドのフランチャイズ比較「huriuri」の企画・開発・運営' },
]

export const CompanyInfo = ({ author, category }: Props) => {
	let email: string

	if (category === 'プレスリリース') {
		email = 'pr@lisatech.jp'
	} else {
		email = 'info@lisatech.jp'
	}

	return (
		<div className={container}>
			<h4 className="text-sm">● Lisa Technologiesについて</h4>
			<p className="mt-4 text-sm leading-7">
				「外食にテクノロジーを」をミッションに、Uber
				Eatsをはじめとしたフードデリバリーの運用支援・最適化SaaSを展開しています。フードデリバリーを導入する飲食店に対して、売上やお客様の行動データを分析し、アカウントに沿った改善提案、運用支援やマーケティング戦略の立案を行うプラットフォームを運営しています。
			</p>
			<div className="mt-16">
				<h4 className="text-sm">● 会社概要</h4>
				{infoData.map((info, i) => {
					return (
						<div key={info.title} className={`flex items-center ${i === 0 ? 'mt-4' : 'mt-2'}`}>
							<span className={classnames(infoTitle, 'inline-block w-14 lg:w-16 text-xs lg:text-sm')}>
								{info.title}
							</span>
							<span className="w-4 text-xs lg:text-sm">：</span>
							<span className="ml-2 text-xs lg:text-sm">{info.text}</span>
						</div>
					)
				})}
			</div>
			<div className="mt-2">
				<span className="inline-block w-14 lg:w-16 text-xs lg:text-sm">HP</span>
				<span className="w-4 text-xs lg:text-sm">：</span>
				<Link to="/" className="text-xs lg:text-sm text-blue hover:opacity-70 duration-300">
					https://lisatech.jp
				</Link>
			</div>
			<div className="mt-1">
				<span className="inline-block w-14 lg:w-16 text-xs lg:text-sm">FDM</span>
				<span className="w-4 text-xs lg:text-sm">：</span>
				<a
					href="https://fooddeliverymanager.jp/"
					className="text-xs lg:text-sm text-blue hover:opacity-70 duration-300"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://fooddeliverymanager.jp
				</a>
			</div>
			<div className="mt-14">
				<h4 className="text-xs lg:text-sm">● 本件に関するお問い合わせ先</h4>
				<p className="mt-2 text-xs lg:text-sm">
					Lisa Technologies 株式会社
					<span className="ml-4" />
					担当：{author.lastName}
				</p>
				<p className="mt-2 text-xs lg:text-sm">Tel： 050-3138-1514</p>
				<p className="mt-2 text-xs lg:text-sm">Mail： {email}</p>
			</div>
		</div>
	)
}
